import { compareByDate } from "../date"

export function compareTopics<
    T extends {
        updatedAtTimestamp?: number | null
        publishedAtTimestamp?: number | null
        articlesInTopic?: unknown[] | null
        memosInTopic?: unknown[] | null
    }
>(a: T, b: T): number {
    let r = (b.articlesInTopic?.length ?? 0) - (a.articlesInTopic?.length ?? 0)

    if (r !== 0) {
        return r
    }

    r = (b.memosInTopic?.length ?? 0) - (a.memosInTopic?.length ?? 0)

    if (r !== 0) {
        return r
    }

    return compareByDate(a, b)
}
