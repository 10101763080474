import React from "react"
import { graphql, PageProps } from "gatsby"
import { Kind } from "../../../common/kind"
import { TopicListPageQuery } from "../../../types/gatsby-graphql.generated"
import { compareTopics } from "../../base/content/topic"
import { localizePlural } from "../../i18n/kind"
import { ListPageHeadLayout, ListPageLayout } from "../../layouts/list"
import { InfoWith, ListItem } from "../../views/content"

export function Head() {
    return <ListPageHeadLayout isHome={false} kind={Kind.Topic} />
}

export default function TopicListPage({ data }: PageProps<TopicListPageQuery>) {
    return (
        <ListPageLayout
            isHome={false}
            kind={Kind.Topic}
            items={data.allMarkdownRemark.nodes
                .sort(compareTopics)
                .map(node => (
                    <ListItem
                        key={node.path}
                        path={node.path}
                        title={node.title}
                        info={
                            <InfoWith
                                updatedAt={node.updatedAt}
                                counts={[
                                    {
                                        title: localizePlural(Kind.Article),
                                        count: node.articlesInTopic,
                                    },
                                    {
                                        title: localizePlural(Kind.Memo),
                                        count: node.memosInTopic,
                                    },
                                ]}
                            />
                        }
                    />
                ))}
        />
    )
}

export const pageQuery = graphql`
    query TopicListPage {
        allMarkdownRemark(
            filter: {
                frontmatter: { kind: { eq: "topic" } }
                published: { eq: true }
            }
        ) {
            nodes {
                path
                title
                updatedAt
                updatedAtTimestamp
                publishedAt
                publishedAtTimestamp
                articlesInTopic {
                    path
                    title
                }
                memosInTopic {
                    path
                    title
                }
            }
        }
    }
`
